var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"empresaSemEmailList",attrs:{"id":"empresaSemEmailList","button-size":"sm","no-close-on-backdrop":"","no-stacking":"","ok-only":"","ok-title":"Ok","cancel-title":"Cancelar","size":"lg","no-enforce-focus":true}},[_c('div',{attrs:{"slot":"modal-title"},slot:"modal-title"},[_c('h4',[_vm._v("Cadastro de email por empresa")]),_c('fieldset',{staticClass:"font-weight-bold text-muted small"},[_vm._v(" Informe o email da empresa e salve para enviar o documento e atualizar o cadastro. ")])]),_c('h5',{},[_vm._v(" Histórico ")]),_c('b-row',[_c('b-col',[_c('tabela-repeticao',{attrs:{"colunas":_vm.colunas,"somente-visualizacao":false,"customizar-botoes":true},scopedSlots:_vm._u([{key:"visualizacao",fn:function(ref){
var ref_slotScope = ref.slotScope;
var key = ref_slotScope.key;
var item = ref_slotScope.item;
var valor = ref_slotScope.valor;
return [(key === 'email')?_c('div',[_c('b-input',{attrs:{"type":"email"},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})],1):_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(valor)+" ")])]}},{key:"botoes-customizados",fn:function(ref){
var item = ref.slotScope.item;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-none rounded-pill m-0 icone-acao p-0 btn-none",attrs:{"variant":"none","title":"Salvar"},on:{"click":function($event){return _vm.salvarEmail(item)}}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":['fas', 'floppy-disk']}})],1)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1)],1),_c('loading',{attrs:{"show-cancel-button":false,"is-loading":_vm.isLoading,"descricao":'Buscando'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }