<template>
  <div>
    <b-modal
      id="empresaSemEmailList"
      ref="empresaSemEmailList"
      button-size="sm"
      no-close-on-backdrop
      no-stacking
      ok-only
      ok-title="Ok"
      cancel-title="Cancelar"
      size="lg"
      :no-enforce-focus="true"
    >
      <div slot="modal-title">
        <h4>Cadastro de email por empresa</h4>
        <fieldset class="font-weight-bold text-muted small">
          Informe o email da empresa e salve para enviar o documento e atualizar o cadastro.
        </fieldset>
      </div>
      <h5
        style=""
      >
        Histórico
      </h5>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="value"
            :colunas="colunas"
            :somente-visualizacao="false"
            :customizar-botoes="true"
          >
            <template #visualizacao="{slotScope: {key, item, valor}}">
              <div v-if="key === 'email'">
                <b-input
                  v-model="item.email"
                  type="email"
                />
              </div>
              <span
                v-else
                class="ml-50"
              >
                {{ valor }}
              </span>
            </template>
            <template #botoes-customizados="{slotScope: {item}}">
              <b-btn
                v-b-tooltip.hover
                variant="none"
                class="btn btn-none rounded-pill m-0 icone-acao p-0 btn-none"
                title="Salvar"
                @click="salvarEmail(item)"
              >
                <font-awesome-icon
                  :icon="['fas', 'floppy-disk']"
                  class="text-primary"
                />
              </b-btn>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
    </b-modal>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}
</style>

<script>

export default {
  name: 'EmpresasSemEmail',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colunas: [
        {
          key: 'razaoSocial', label: 'Razão Social',
        },
        {
          key: 'cadastroFederal', label: 'CNPJ',
        },
        {
          key: 'email', label: 'E-mail',
        }],
      isLoading: false,
    }
  },
  methods: {
    show() {
      this.$refs.empresaSemEmailList.show()
    },
    pararEspera() {
      this.isLoading = false
    },
    iniciarEspera() {
      this.isLoading = true
    },
    openEnvioEmail(item) {
      this.$emit('envioEmail', { id: item.id })
    },
    visualizarGuia(item, isDarf) {
      this.$emit('mostrarGuia', { id: item.id, isDarf })
    },
    validator(tag) {
      const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(tag)
    },
    salvarEmail(empresa) {
      if (this.validator(empresa.email)) {
        this.$emit('salvar-email', empresa)
      } else {
        this.$erro('E-mail inválido')
      }
    },
  },
}

</script>
